import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"
import IconMetaRow from "../../components/IconMetaRow"

export const pageQuery = graphql`
  query {
    elDoradoHillsImage: file(relativePath: { eq: "el-dorado-hills.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithRekeyingLockImage: file(
      relativePath: { eq: "locksmith-rekeying-lock.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInVanImage: file(relativePath: { eq: "leo-in-van.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function ElDoradoHillsPage({ data }) {
  return (
    <MainLayout
      title="916-995-0135 - 24/7 Locksmith Company - El Dorado Hills, CA"
      description="We offer high quality service at the lowest prices. Car key replacement, auto-unlock, house lockouts, re-key & replace locks. Licensed, Bonded & Insured."
      offset
    >
      <ContactHeading
        background={data.elDoradoHillsImage.childImageSharp.fluid}
      >
        <h1 className="text-center">El Dorado Hills Locksmith</h1>
        <h2 className="text-center font-weight-light">
          24 Hour El Dorado Hills Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2>Leading Locksmith in El Dorado Hills</h2>
        <p className="indent">
          Swift Locksmith is a family owned mobile locksmith business that's
          been giving top notch, affordable and dependable locksmith services
          across the greater El Dorado Hills area for over a decade! We're fully
          licensed, bonded and insured and we've consistently kept a superb
          rating with the Better Business Bureau. We've got extensive knowledge
          and expertise in all aspects of vehicle, residential and commercial
          locksmith security. Our exceptional team of skilled locksmiths offer
          mobile locksmith services in El Dorado Hills, CA. We pride ourselves
          on always providing fast, dependable and inexpensive locksmith
          services which you can trust and are available 24/7.
        </p>
        <p className="indent mb-0">
          Our professional staff can arrive to your El Dorado Hills area
          location within thirty minutes of your call. Our mobile locksmith vans
          are all equipped with the best high tech equipment and carry a wide
          variety of locks and related hardware to meet all of your security or
          emergency lock out needs on the spot. Whether you are looking for
          emergency lockout services or have residential or business security
          needs, Swift Locksmith in El Dorado Hills can provide the maximum
          degree of service with competitive pricing.
        </p>
      </Container>
      <Container as="section" className="d-none d-sm-block">
        <Row>
          <Col sm={6} style={{ marginBottom: 30 }}>
            <Img
              fluid={data.leoInCarImage.childImageSharp.fluid}
              alt="leo-in-car"
              style={{ height: "100%" }}
            />
          </Col>
          <Col sm={6} style={{ marginBottom: 30 }}>
            <Img
              fluid={data.locksmithRekeyingLockImage.childImageSharp.fluid}
              alt="locksmith-rekeying-lock"
              style={{ height: "100%" }}
            />
          </Col>
          <Col sm={6}>
            <Img
              fluid={data.locksmithWithCarImage.childImageSharp.fluid}
              alt="locksmith-with-car"
              style={{ height: "100%" }}
            />
          </Col>
          <Col sm={6}>
            <Img
              fluid={data.leoInVanImage.childImageSharp.fluid}
              alt="leo-in-van"
              style={{ height: "100%" }}
            />
          </Col>
        </Row>
      </Container>
      <Container as="section">
        <Row>
          <Col xs={12} sm={9} md={6} lg={5} className="mb-3 mx-auto">
            <h2>Residential Services</h2>
            <ul className="mb-0">
              <li>24/7 Emergency Lockout</li>
              <li>Lock Repair & Maintenance</li>
              <li>Door Knob Lock Installation</li>
              <li>Duplicate House Keys</li>
              <li>High Security Locks</li>
              <li>Master Key Systems</li>
              <li>On-Site Key Extraction</li>
              <li>Whole House Re-key</li>
            </ul>
          </Col>
          <Col xs={12} sm={9} md={6} lg={5} className="mb-3 mx-auto">
            <h2>Automotive Services</h2>
            <ul className="mb-0">
              <li>24/7 Lock Out Services</li>
              <li>Broken Key Extraction</li>
              <li>Ignition Rekey</li>
              <li>Laser Cut Keys</li>
              <li>Chip Keys Cut Services</li>
              <li>Remote Programming</li>
              <li>Lost Car Key Replacement</li>
              <li>Replace Keyless Entry Remotes</li>
            </ul>
          </Col>
          <Col xs={12} sm={9} md={6} lg={5} className="mb-3 mb-md-0 mx-auto">
            <h2>Commercial Services</h2>
            <ul className="mb-0">
              <li>24/7 Emergency Lockout</li>
              <li>Business Re-key</li>
              <li>Locks & Exit Device Repair</li>
              <li>Master Key system Installation</li>
              <li>Keyless Access System</li>
              <li>High Security & Standard Deadbolts</li>
              <li>On-site Key Duplication</li>
              <li>Aluminum Door Lock Alignment & Installation</li>
            </ul>
          </Col>
          <Col xs={12} sm={9} md={6} lg={5} className="mx-auto">
            <h2>Emergency Locksmith</h2>
            <ul className="mb-0">
              <li>Car, House, Office Lockouts</li>
              <li>Emergency Rekey Services</li>
              <li>Emergency Lock Repair Services</li>
              <li>Bedroom, Storage, Garage Lockouts</li>
              <li>Replacement of Lost Car Keys</li>
              <li>Broken Key Extraction</li>
              <li>Mail Box Lockouts</li>
              <li>Lock Picking</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container as="section">
        <h2>Why Choose Swift Locksmith</h2>
        <p className="indent mb-0">
          By choosing us you’re putting expertise to work where it matters most,
          the well-being and security for you, your home and your business. With
          more than 10 years of experience in the locksmith industry, our team
          provides in-depth automotive, commercial, and residential locksmith
          services for the El Dorado Hills community. From key duplication to
          lock sales and repairs, we have the tools to successfully assist and
          resolve any locksmith need.
        </p>
      </Container>
      <Container as="section" className="mb-5">
        <IconMetaRow />
      </Container>
    </MainLayout>
  )
}

export default ElDoradoHillsPage
